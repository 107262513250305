// components/layout/ThemeToggle.tsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Moon, Monitor, Check } from 'lucide-react';
import { useTheme } from '../../hooks/useTheme';


interface ThemeOption {
  id: 'light' | 'dark' | 'system';
  label: string;
  icon: React.ReactNode;
}

const ThemeToggle: React.FC = () => {
  const { theme, setTheme, effectiveTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredTheme, setHoveredTheme] = useState<string | null>(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest('#theme-toggle')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  const themeOptions: ThemeOption[] = [
    {
      id: 'light',
      label: 'Light',
      icon: <Sun className="w-4 h-4" />
    },
    {
      id: 'dark',
      label: 'Dark',
      icon: <Moon className="w-4 h-4" />
    },
    {
      id: 'system',
      label: 'System',
      icon: <Monitor className="w-4 h-4" />
    }
  ];

  const getCurrentIcon = () => {
    switch (effectiveTheme) {
      case 'dark':
        return <Moon className="w-5 h-5" />;
      case 'light':
        return <Sun className="w-5 h-5" />;
      default:
        return <Monitor className="w-5 h-5" />;
    }
  };

  return (
    <div className="relative" id="theme-toggle">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="
          p-2 rounded-md
          hover:bg-muted
          transition-colors duration-200
          focus:outline-none focus:ring-2 focus:ring-primary/20
        "
        title={`Current theme: ${theme}`}
      >
        <AnimatePresence mode="wait">
          <motion.span
            key={effectiveTheme}
            initial={{ opacity: 0, rotate: -45 }}
            animate={{ opacity: 1, rotate: 0 }}
            exit={{ opacity: 0, rotate: 45 }}
            transition={{ duration: 0.2 }}
          >
            {getCurrentIcon()}
          </motion.span>
        </AnimatePresence>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1 }}
            className="
              absolute right-0 mt-2 z-50
              w-48 p-1
              bg-popover border border-border
              rounded-lg shadow-lg
            "
          >
            {themeOptions.map((option) => (
              <motion.button
                key={option.id}
                onClick={() => {
                  setTheme(option.id);
                  setIsOpen(false);
                }}
                onMouseEnter={() => setHoveredTheme(option.id)}
                onMouseLeave={() => setHoveredTheme(null)}
                className={`
                  flex items-center justify-between
                  w-full px-3 py-2 gap-2
                  text-sm rounded-md
                  transition-colors duration-200
                  ${theme === option.id
                    ? 'bg-primary/10 text-primary'
                    : 'hover:bg-muted'
                  }
                `}
              >
                <span className="flex items-center gap-2">
                  <motion.span
                    animate={hoveredTheme === option.id
                      ? { scale: 1.2, rotate: 360 }
                      : { scale: 1, rotate: 0 }
                    }
                    transition={{ duration: 0.3 }}
                  >
                    {option.icon}
                  </motion.span>
                  {option.label}
                </span>
                {theme === option.id && (
                  <motion.span
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <Check className="w-4 h-4" />
                  </motion.span>
                )}
              </motion.button>
            ))}

            {/* System Theme Status */}
            {theme === 'system' && (
              <div className="
                mt-1 pt-2 px-3 pb-2
                text-xs text-muted-foreground
                border-t border-border
              ">
                System theme: {effectiveTheme === 'dark' ? 'Dark' : 'Light'}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ThemeToggle;