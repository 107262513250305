import { useEffect } from 'react';
import { useSettingsStore } from '../store/settingsStore';

type Theme = 'light' | 'dark' | 'system';

export const useTheme = () => {
  const { appearance, updateAppearance } = useSettingsStore();
  const { theme, accentColor } = appearance;

  useEffect(() => {
    // Handle system theme preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const updateTheme = () => {
      const root = window.document.documentElement;
      const isSystemDark = mediaQuery.matches;
      const effectiveTheme = theme === 'system' ? (isSystemDark ? 'dark' : 'light') : theme;
      
      // Update root element classes
      root.classList.remove('light', 'dark');
      root.classList.add(effectiveTheme);
      
      // Update theme color classes
      root.classList.remove('theme-purple', 'theme-blue', 'theme-green');
      root.classList.add(`theme-${accentColor}`);
      
      // Update meta theme-color for mobile browsers
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute(
          'content',
          effectiveTheme === 'dark' ? '#1a1b1e' : '#ffffff'
        );
      }
    };

    updateTheme();
    mediaQuery.addEventListener('change', updateTheme);
    
    return () => mediaQuery.removeEventListener('change', updateTheme);
  }, [theme, accentColor]);

  const setTheme = (newTheme: Theme) => {
    updateAppearance({ theme: newTheme });
  };

  const setAccentColor = (newColor: typeof accentColor) => {
    updateAppearance({ accentColor: newColor });
  };

  const toggleTheme = () => {
    const themeOrder: Theme[] = ['light', 'dark', 'system'];
    const currentIndex = themeOrder.indexOf(theme);
    const nextTheme = themeOrder[(currentIndex + 1) % themeOrder.length];
    setTheme(nextTheme);
  };

  return {
    theme,
    accentColor,
    setTheme,
    setAccentColor,
    toggleTheme,
    isDark: theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches),
    effectiveTheme: theme === 'system' 
      ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
      : theme
  };
};