import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import {
  Bot,
  Book,
  BarChart2,
  Layers,
  Monitor,
  Code,
  Cpu,
  Repeat,
  Zap,
  Keyboard,
  Settings,
  MessageSquare,
  RefreshCw,
  ArrowRight,
  ChevronRight,
  Search,
  Shield,
  LineChart,
  PieChart,
  Activity,
  Users,
  Clock,
  Calendar,
  CheckCircle,
  XCircle
} from 'lucide-react';

// Mock data for the interactive features
const interactiveFeatures = [
  {
    id: 'automation',
    title: 'Workflow Automation',
    description: 'Automate repetitive tasks with AI-powered workflows',
    icon: <Cpu className="w-6 h-6" />,
    metrics: {
      efficiency: 85,
      timesSaved: 1240,
      automationCount: 45
    },
    color: 'from-violet-500/20 to-fuchsia-500/20',
    mockData: {
      graph: {
        title: 'Automation Performance',
        data: [65, 75, 85, 82, 90, 88, 95]
      },
      stats: [
        { label: 'Active Workflows', value: '24/45' },
        { label: 'Success Rate', value: '99.2%' },
        { label: 'Avg Response', value: '1.2s' },
        { label: 'Tasks/Hour', value: '486' }
      ],
      activities: [
        { time: '2min ago', status: 'success', text: 'Data sync completed' },
        { time: '5min ago', status: 'pending', text: 'Processing batch #2421' },
        { time: '12min ago', status: 'success', text: 'Report generated' }
      ]
    }
  },
  {
    id: 'integrations',
    title: 'Smart Integrations',
    description: 'Seamlessly connect with your favorite tools and platforms',
    icon: <Layers className="w-6 h-6" />,
    metrics: {
      connectedApps: 50,
      dataPoints: 10000,
      syncRate: 99.9
    },
    color: 'from-blue-500/20 to-cyan-500/20',
    mockData: {
      graph: {
        title: 'Integration Health',
        data: [99.8, 99.9, 99.7, 99.9, 100, 99.9, 99.8]
      },
      stats: [
        { label: 'Active Connections', value: '47/50' },
        { label: 'Data Synced', value: '2.4TB' },
        { label: 'API Calls', value: '25k/hr' },
        { label: 'Response Time', value: '45ms' }
      ],
      activities: [
        { time: '1min ago', status: 'success', text: 'Slack integration sync' },
        { time: '3min ago', status: 'success', text: 'GitHub webhook received' },
        { time: '8min ago', status: 'pending', text: 'Updating Jira status' }
      ]
    }
  },
  {
    id: 'insights',
    title: 'Advanced Analytics',
    description: 'Deep insights into your productivity patterns',
    icon: <BarChart2 className="w-6 h-6" />,
    metrics: {
      accuracy: 95,
      predictions: 500,
      dataPoints: 25000
    },
    color: 'from-emerald-500/20 to-green-500/20',
    mockData: {
      graph: {
        title: 'Prediction Accuracy',
        data: [92, 94, 93, 95, 96, 95, 97]
      },
      stats: [
        { label: 'ML Models', value: '12/15' },
        { label: 'Training Data', value: '1.2M' },
        { label: 'Accuracy', value: '97.5%' },
        { label: 'Predictions', value: '5.2k' }
      ],
      activities: [
        { time: 'Just now', status: 'success', text: 'Model retrained' },
        { time: '4min ago', status: 'success', text: 'New insights generated' },
        { time: '15min ago', status: 'pending', text: 'Analyzing patterns' }
      ]
    }
  }
];

const techFeatures = [
  { icon: <Code />, label: 'API Access' },
  { icon: <Shield />, label: 'Enterprise Security' },
  { icon: <RefreshCw />, label: 'Real-time Sync' },
  { icon: <Bot />, label: 'AI Models' },
  { icon: <Monitor />, label: 'Custom Dashboard' },
  { icon: <Settings />, label: 'Advanced Config' }
];

export default function HomeDashboard() {
  const [activeFeature, setActiveFeature] = useState(0);
  const [isHovered, setIsHovered] = useState<string | null>(null);
  const { scrollYProgress } = useScroll();
  
  const backgroundOpacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const contentScale = useTransform(scrollYProgress, [0.2, 0.8], [0.95, 1]);

  // Animate through features
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % interactiveFeatures.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <motion.section 
      className="relative py-24 overflow-hidden"
      style={{ scale: contentScale }}
    >
      {/* Background Elements */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-b from-background via-primary/5 to-background"
        style={{ opacity: backgroundOpacity }}
      />
      
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="bg-primary/10 text-primary rounded-full px-4 py-1.5 text-sm font-medium inline-flex items-center gap-2 mb-4"
          >
            <Zap className="w-4 h-4" />
            Advanced Capabilities
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold mb-4"
          >
            Enterprise-Grade Features
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-xl text-muted-foreground max-w-2xl mx-auto"
          >
            Powerful tools and integrations designed for professional teams
          </motion.p>
        </div>

        {/* Interactive Features */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-24">
          {/* Feature Cards */}
          <div className="space-y-6">
            {interactiveFeatures.map((feature, index) => (
              <motion.div
                key={feature.id}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                onClick={() => setActiveFeature(index)}
                className={`relative p-6 rounded-xl cursor-pointer border group
                  ${activeFeature === index 
                    ? 'bg-gradient-to-r border-primary shadow-lg ' + feature.color
                    : 'hover:bg-muted/50 border-border'
                  }`}
              >
                <div className="flex items-start gap-4">
                  <div className={`p-2 rounded-lg transition-colors
                    ${activeFeature === index 
                      ? 'bg-background/50 text-primary' 
                      : 'bg-muted text-muted-foreground'
                    }`}
                  >
                    {feature.icon}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-muted-foreground">{feature.description}</p>
                    
                    <AnimatePresence mode="wait">
                      {activeFeature === index && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.2 }}
                          className="mt-4 grid grid-cols-3 gap-4"
                        >
                          {Object.entries(feature.metrics).map(([key, value]) => (
                            <div key={key} className="bg-background/50 rounded-lg p-3">
                              <div className="text-xl font-bold">
                                {typeof value === 'number' && value > 1000 
                                  ? `${(value / 1000).toFixed(1)}k` 
                                  : value}
                                {key === 'syncRate' && '%'}
                              </div>
                              <div className="text-xs text-muted-foreground capitalize">
                                {key.replace(/([A-Z])/g, ' $1').trim()}
                              </div>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <ChevronRight className={`w-5 h-5 transition-transform
                    ${activeFeature === index ? 'rotate-90' : 'group-hover:translate-x-1'}
                  `} />
                </div>
              </motion.div>
            ))}
          </div>

          {/* Interactive Preview */}
          <div className="relative lg:sticky lg:top-24 h-[600px]">
            <motion.div
              className="absolute inset-0 rounded-2xl border border-border bg-card shadow-2xl overflow-hidden"
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
            >
              <div className="p-4 border-b border-border flex items-center justify-between bg-muted/50">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-red-500" />
                  <div className="w-3 h-3 rounded-full bg-yellow-500" />
                  <div className="w-3 h-3 rounded-full bg-green-500" />
                </div>
                <div className="text-sm text-muted-foreground">enterprise.ai</div>
              </div>

              <div className="p-6">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeFeature}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="space-y-6"
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold">
                        {interactiveFeatures[activeFeature].title} Dashboard
                      </h3>
                      <div className="flex items-center gap-2">
                        <button className="p-2 hover:bg-muted rounded-md transition-colors">
                          <Search className="w-4 h-4" />
                        </button>
                        <button className="p-2 hover:bg-muted rounded-md transition-colors">
                          <Settings className="w-4 h-4" />
                        </button>
                      </div>
                    </div>

                    {/* Mock Dashboard Content */}
                    <div className="space-y-4">
                      {/* Main Graph */}
                      <div className="col-span-2 h-48 rounded-lg bg-muted/50 border border-border p-4">
                        <div className="flex items-center justify-between mb-4">
                          <h4 className="text-sm font-medium">
                            {interactiveFeatures[activeFeature].mockData.graph.title}
                          </h4>
                          <div className="flex items-center gap-2 text-xs text-muted-foreground">
                            <Clock className="w-4 h-4" />
                            Last 7 days
                          </div>
                        </div>
                        <div className="h-32 flex items-end justify-between gap-2">
                          {interactiveFeatures[activeFeature].mockData.graph.data.map((value, i) => (
                            <div
                              key={i}
                              className="w-full bg-primary/20 rounded-t transition-all duration-500"
                              style={{ height: `${value}%` }}
                            />
                          ))}
                        </div>
                      </div>

                      {/* Stats Grid */}
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {interactiveFeatures[activeFeature].mockData.stats.map((stat, i) => (
                          <div key={i} className="p-4 rounded-lg bg-muted/50 border border-border">
                            <div className="text-sm text-muted-foreground mb-1">{stat.label}</div>
                            <div className="text-2xl font-bold">{stat.value}</div>
                          </div>
                        ))}
                      </div>

                      {/* Recent Activity */}
                      <div className="rounded-lg bg-muted/50 border border-border p-4">
                        <h4 className="text-sm font-medium mb-4">Recent Activity</h4>
                        <div className="space-y-3">
                          {interactiveFeatures[activeFeature].mockData.activities.map((activity, i) => (
                            <div key={i} className="flex items-center justify-between text-sm">
                              <div className="flex items-center gap-2">
                                {activity.status === 'success' ? (
                                  <CheckCircle className="w-4 h-4 text-green-500" />
                                ) : (
                                  <Activity className="w-4 h-4 text-blue-500 animate-pulse" />
                                )}
                                {activity.text}
                              </div>
                              <span className="text-muted-foreground">{activity.time}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Technical Features Grid */}
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/10 via-transparent to-primary/10 rounded-3xl" />
          <div className="relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 p-8 rounded-3xl">
            {techFeatures.map((feature, index) => (
              <motion.div
                key={feature.label}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                onMouseEnter={() => setIsHovered(feature.label)}
                onMouseLeave={() => setIsHovered(null)}
                className="relative group"
              >
                <div className="text-center p-4 rounded-xl bg-card border border-border hover:border-primary transition-colors">
                  <motion.div
                    animate={{
                      scale: isHovered === feature.label ? 1.1 : 1,
                      rotate: isHovered === feature.label ? 360 : 0
                    }}
                    transition={{ duration: 0.3 }}
                    className="mx-auto mb-3 w-10 h-10 rounded-lg bg-primary/10 text-primary 
                             flex items-center justify-center"
                  >
                    {feature.icon}
                  </motion.div>
                  <div className="text-sm font-medium">{feature.label}</div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Bottom CTA */}
        <div className="mt-24 text-center">
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="px-8 py-4 bg-primary text-primary-foreground rounded-lg font-medium 
                     hover:bg-primary/90 transition-colors inline-flex items-center gap-2
                     group relative overflow-hidden"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <span className="relative z-10">Book a Demo</span>
            <ArrowRight className="w-4 h-4 relative z-10 group-hover:translate-x-1 transition-transform" />
            <motion.div
              className="absolute inset-0 bg-background mix-blend-difference"
              initial={{ x: '-100%' }}
              whileHover={{ x: '100%' }}
              transition={{ duration: 0.4 }}
            />
          </motion.button>
        </div>
      </div>
    </motion.section>
  );
}
