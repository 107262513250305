import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import {
  Brain,
  Zap,
  LineChart,
  Fingerprint,
  Binary,
  Orbit,
  Workflow,
  Network,
  FlaskConical,
  Lightbulb,
  Compass,
  Target,
  Gem,
  BadgeCheck,
  Infinity,
  Award,
  Sparkles,
  ArrowRight,
  ChevronRight,
  Crown,
  Shield
} from 'lucide-react';
import {
  LineChart as RechartsLineChart,
  Line,
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';

// Mock performance data
const performanceData = Array.from({ length: 7 }, (_, i) => ({
  day: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][i],
  focus: 75 + Math.random() * 20,
  energy: 70 + Math.random() * 25,
  productivity: 80 + Math.random() * 15,
}));

// AI-powered features for high performance
const aiFeatures = [
  {
    id: 'neural',
    title: 'Neural Pattern Recognition',
    description: 'AI analyzes your peak performance patterns to optimize daily schedules',
    icon: <Binary />,
    metrics: {
      accuracy: 98.5,
      patterns: 1250,
      insights: 45
    },
    color: 'from-violet-500 to-purple-600'
  },
  {
    id: 'biometrics',
    title: 'Advanced Biometric Analysis',
    description: 'Real-time monitoring of focus, energy, and cognitive performance',
    icon: <Fingerprint />,
    metrics: {
      dataPoints: 10000,
      accuracy: 99.2,
      signals: 85
    },
    color: 'from-cyan-500 to-blue-600'
  },
  {
    id: 'quantum',
    title: 'Quantum Performance Engine',
    description: 'Next-gen algorithms for superhuman productivity optimization',
    icon: <Orbit />,
    metrics: {
      calculations: 1000000,
      precision: 99.9,
      predictions: 150
    },
    color: 'from-emerald-500 to-green-600'
  }
];

// Performance enhancement modules
const enhancementModules = [
  {
    icon: <FlaskConical />,
    title: 'Flow State Inducer',
    description: 'AI-guided protocols to achieve and maintain peak flow states'
  },
  {
    icon: <Lightbulb />,
    title: 'Cognitive Enhancer',
    description: 'Neural pathway optimization for enhanced mental performance'
  },
  {
    icon: <Compass />,
    title: 'Success Navigator',
    description: 'Predictive guidance system for optimal decision making'
  },
  {
    icon: <Network />,
    title: 'Neural Network',
    description: 'Deep learning system that evolves with your performance'
  }
];

// Performance metrics
const performanceMetrics = [
  { label: 'Neural Pathways', value: '150K+', icon: Brain },
  { label: 'Peak States', value: '500+', icon: Zap },
  { label: 'Success Rate', value: '99.9%', icon: Target },
  { label: 'Optimization Level', value: 'Level 9', icon: Gem }
];

export default function HomePerformance() {
  const [activeFeature, setActiveFeature] = useState(0);
  const [hoverState, setHoverState] = useState<string | null>(null);
  const { scrollYProgress } = useScroll();
  const [isSimulating, setIsSimulating] = useState(false);

  const y = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0.5]);

  // Brain wave simulation effect
  const startSimulation = useCallback(() => {
    setIsSimulating(true);
    setTimeout(() => setIsSimulating(false), 2000);
  }, []);

  // Auto-rotate features
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % aiFeatures.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Animated Background Elements */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-b from-background via-primary/5 to-background"
        style={{ y, opacity }}
      />
      
      <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center gap-2 bg-primary/10 text-primary rounded-full px-4 py-1.5 mb-4"
          >
            <Crown className="w-4 h-4" />
            <span className="text-sm font-medium">High Performance Humans</span>
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold mb-4"
          >
            Elevate Human Potential with AI
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-xl text-muted-foreground max-w-3xl mx-auto"
          >
            Unlock superhuman capabilities through advanced AI-powered performance optimization
          </motion.p>
        </div>

        {/* AI Features Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-24">
          {/* Feature Cards */}
          <div className="space-y-6">
            {aiFeatures.map((feature, index) => (
              <motion.div
                key={feature.id}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                onClick={() => setActiveFeature(index)}
                className={`relative p-6 rounded-xl cursor-pointer border group
                  ${activeFeature === index 
                    ? 'bg-gradient-to-r border-primary shadow-lg' 
                    : 'hover:bg-muted/50 border-border'
                  }`}
              >
                <div className="flex items-start gap-4">
                  <motion.div
                    className={`p-3 rounded-xl transition-colors
                      ${activeFeature === index 
                        ? 'bg-gradient-to-br ' + feature.color + ' text-white' 
                        : 'bg-muted text-muted-foreground'
                      }`}
                    whileHover={{ scale: 1.05, rotate: 5 }}
                  >
                    {feature.icon}
                  </motion.div>

                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                      <ChevronRight className={`w-5 h-5 transition-transform duration-300
                        ${activeFeature === index ? 'rotate-90' : 'group-hover:translate-x-1'}
                      `} />
                    </div>
                    <p className="text-muted-foreground mb-4">{feature.description}</p>

                    <AnimatePresence mode="wait">
                      {activeFeature === index && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.2 }}
                          className="grid grid-cols-3 gap-4"
                        >
                          {Object.entries(feature.metrics).map(([key, value]) => (
                            <div key={key} className="bg-background/50 rounded-lg p-3">
                              <div className="text-xl font-bold">
                                {typeof value === 'number' && value > 1000 
                                  ? `${(value / 1000).toFixed(1)}k` 
                                  : value}
                                {key === 'accuracy' && '%'}
                              </div>
                              <div className="text-xs text-muted-foreground capitalize">
                                {key.replace(/([A-Z])/g, ' $1').trim()}
                              </div>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Interactive Neural Interface */}
          <div className="relative lg:sticky lg:top-24 h-[600px]">
            <motion.div
              className="absolute inset-0 rounded-2xl border border-border bg-card shadow-2xl overflow-hidden"
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
            >
              {/* Interface Header */}
              <div className="p-4 border-b border-border bg-muted/50 flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Brain className="w-5 h-5 text-primary" />
                  <span className="font-semibold">Neural Interface v2.0</span>
                </div>
                <div className="flex items-center gap-2">
                  <motion.div
                    animate={{
                      scale: isSimulating ? [1, 1.2, 1] : 1,
                      opacity: isSimulating ? [1, 0.7, 1] : 1
                    }}
                    transition={{ 
                        duration: 1, 
                        repeat: isSimulating ? Number.POSITIVE_INFINITY : 0 
                      }}
                    className="w-2 h-2 rounded-full bg-green-500"
                  />
                  <span className="text-sm text-muted-foreground">Active</span>
                </div>
              </div>

              {/* Neural Visualization */}
              <div className="p-6">
                <AnimatePresence mode="wait">
                  <motion.div
                    key={activeFeature}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="space-y-6"
                  >
                    {/* Performance Graph */}
                    <div className="h-64 rounded-lg bg-muted/10 p-4 border border-border">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={performanceData}>
                          <defs>
                            <linearGradient id="performanceGradient" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="0%" stopColor="hsl(var(--primary))" stopOpacity={0.5} />
                              <stop offset="100%" stopColor="hsl(var(--primary))" stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="day" stroke="hsl(var(--muted-foreground))" />
                          <YAxis stroke="hsl(var(--muted-foreground))" />
                          <Tooltip
                            contentStyle={{
                              background: 'hsl(var(--card))',
                              border: '1px solid hsl(var(--border))',
                              borderRadius: '8px'
                            }}
                          />
                          <Area
                            type="monotone"
                            dataKey="focus"
                            stroke="hsl(var(--primary))"
                            fillOpacity={1}
                            fill="url(#performanceGradient)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>

                    {/* Neural Metrics */}
                    <div className="grid grid-cols-2 gap-4">
                      {[
                        { label: 'Neural Activity', value: '98%', trend: '+2.5%' },
                        { label: 'Cognitive Load', value: '42%', trend: '-5%' },
                        { label: 'Focus Depth', value: '85%', trend: '+7%' },
                        { label: 'Energy Level', value: '92%', trend: '+3%' }
                      ].map((metric, i) => (
                        <div key={i} className="p-4 rounded-lg bg-muted/10 border border-border">
                          <div className="text-sm text-muted-foreground mb-1">
                            {metric.label}
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="text-2xl font-bold">{metric.value}</span>
                            <span className={`text-sm ${
                              metric.trend.startsWith('+') ? 'text-green-500' : 'text-red-500'
                            }`}>
                              {metric.trend}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Neural Network Status */}
                    <div className="flex justify-between items-center p-4 rounded-lg bg-primary/5 border border-primary/20">
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 rounded-full bg-primary animate-pulse" />
                        <span className="text-sm font-medium">Neural Network Active</span>
                      </div>
                      <button
                        onClick={startSimulation}
                        className="px-3 py-1.5 text-sm bg-primary text-primary-foreground rounded-md
                                 hover:bg-primary/90 transition-colors"
                      >
                        Simulate
                      </button>
                    </div>
                  </motion.div>
                </AnimatePresence>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Enhancement Modules */}
        <div className="mb-24">
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-2xl font-bold text-center mb-12"
          >
            Performance Enhancement Modules
          </motion.h3>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {enhancementModules.map((module, index) => (
              <motion.div
                key={module.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.02 }}
                className="relative group"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-primary/10 to-primary/5 
                               rounded-xl blur-xl transition-opacity opacity-0 group-hover:opacity-100" />
                
                <div className="relative p-6 rounded-xl border border-border bg-card hover:border-primary 
                               transition-colors duration-300">
                  <motion.div
                    whileHover={{ rotate: [0, -10, 10, -5, 5, 0] }}
                    transition={{ duration: 0.5 }}
                    className="w-12 h-12 rounded-lg bg-primary/10 text-primary 
                             flex items-center justify-center mb-4"
                  >
                    {module.icon}
                  </motion.div>

                  <h4 className="text-lg font-semibold mb-2">{module.title}</h4>
                  <p className="text-muted-foreground text-sm">{module.description}</p>

                  <div className="mt-4 pt-4 border-t border-border">
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-muted-foreground">Success rate</span>
                      <span className="font-medium text-primary">98.5%</span>
                    </div>
                    <div className="mt-2 h-1.5 bg-muted rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        whileInView={{ width: '98.5%' }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.5, duration: 1, ease: 'easeOut' }}
                        className="h-full bg-primary rounded-full"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Performance Metrics */}
        <div className="relative mb-24">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/10 via-transparent to-primary/10 rounded-3xl" />
          
          <div className="relative grid grid-cols-2 md:grid-cols-4 gap-6 p-8 rounded-3xl">
            {performanceMetrics.map((metric, index) => (
              <motion.div
                key={metric.label}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-center"
              >
                <motion.div
                  whileHover={{ scale: 1.1, rotate: 360 }}
                  transition={{ type: "spring", stiffness: 200, damping: 10 }}
                  className="w-16 h-16 rounded-2xl bg-primary/10 text-primary mx-auto mb-4
                           flex items-center justify-center"
                >
                  <metric.icon className="w-8 h-8" />
                </motion.div>
                
                <div className="text-2xl font-bold mb-1">{metric.value}</div>
                <div className="text-sm text-muted-foreground">{metric.label}</div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Final CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center relative"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-primary/20 via-primary/5 to-primary/20 
                         rounded-3xl blur-xl" />
          
          <div className="relative p-12 rounded-3xl border border-border">
            <motion.div
              initial={{ scale: 0.9 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              className="w-20 h-20 rounded-2xl bg-primary/10 text-primary mx-auto mb-6
                       flex items-center justify-center"
            >
              <Infinity className="w-10 h-10" />
            </motion.div>

            <h3 className="text-3xl font-bold mb-4">
              Unlock Your Full Potential
            </h3>
            
            <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
              Join the next generation of high performers using AI-powered optimization
            </p>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-8 py-4 bg-primary text-primary-foreground rounded-xl font-medium 
                       inline-flex items-center gap-2 group relative overflow-hidden"
            >
              <span className="relative z-10">Start Optimizing Now</span>
              <ArrowRight className="w-5 h-5 relative z-10 group-hover:translate-x-1 transition-transform" />
              <motion.div
                className="absolute inset-0 bg-background mix-blend-difference"
                initial={{ x: '-100%' }}
                whileHover={{ x: '100%' }}
                transition={{ duration: 0.4 }}
              />
            </motion.button>

            <div className="mt-8 flex items-center justify-center gap-8 text-muted-foreground">
              <div className="flex items-center gap-2">
                <BadgeCheck className="w-5 h-5" />
                <span>30-day trial</span>
              </div>
              <div className="flex items-center gap-2">
                <Shield className="w-5 h-5" />
                <span>Enterprise ready</span>
              </div>
              <div className="flex items-center gap-2">
                <Award className="w-5 h-5" />
                <span>Top rated</span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}