import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import {
  Brain, Star, Sparkles, ArrowRight, CheckCircle2, Zap, 
  Target, Clock, Play, ChevronRight, Users, Shield,
  Globe, Blocks, Laptop, Code, Wand2, Bot, Rocket,
  Calendar
} from 'lucide-react';
import { LineChart, Line, AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const mockProductivityData = Array.from({ length: 12 }, (_, i) => ({
  month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][i],
  productivity: 65 + Math.random() * 30,
  focus: 55 + Math.random() * 40,
  tasks: 45 + Math.random() * 50
}));

const features = [
  {
    id: 'ai-assistant',
    title: 'AI Assistant',
    description: 'Leverage advanced machine learning for personalized productivity insights',
    icon: <Brain />,
    color: 'purple',
    preview: (
      <div className="p-4 bg-purple-500/10 rounded-lg space-y-3">
        <div className="flex items-center gap-2">
          <Bot className="w-5 h-5 text-purple-500" />
          <div className="text-sm">AI Suggestion</div>
        </div>
        <div className="animate-pulse space-y-2">
          <div className="h-2 bg-purple-500/20 rounded w-3/4"></div>
          <div className="h-2 bg-purple-500/20 rounded w-1/2"></div>
        </div>
      </div>
    )
  },
  {
    id: 'smart-scheduling',
    title: 'Smart Scheduling',
    description: 'Optimize your calendar with AI-powered time management',
    icon: <Clock />,
    color: 'blue',
    preview: (
      <div className="p-4 bg-blue-500/10 rounded-lg">
        <div className="grid grid-cols-7 gap-1">
          {Array.from({ length: 7 }).map((_, i) => (
            <div key={i} className="aspect-square">
              <div className={`w-full h-full rounded-md ${i % 2 ? 'bg-blue-500/20' : 'bg-blue-500/40'}`}></div>
            </div>
          ))}
        </div>
      </div>
    )
  },
  {
    id: 'focus-tracking',
    title: 'Focus Tracking',
    description: 'Monitor and improve your deep work sessions with real-time analytics',
    icon: <Target />,
    color: 'green',
    preview: (
      <div className="p-4 bg-green-500/10 rounded-lg">
        <ResponsiveContainer width="100%" height={60}>
          <AreaChart data={mockProductivityData.slice(-7)}>
            <Area
              type="monotone"
              dataKey="focus"
              stroke="rgb(34 197 94)"
              fill="rgb(34 197 94 / 0.2)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  },
  {
    id: 'team-sync',
    title: 'Team Sync',
    description: 'Seamlessly collaborate and share insights with your team',
    icon: <Users />,
    color: 'orange',
    preview: (
      <div className="p-4 bg-orange-500/10 rounded-lg flex justify-center">
        <div className="flex -space-x-2">
          {Array.from({ length: 4 }).map((_, i) => (
            <div
              key={i}
              className="w-8 h-8 rounded-full bg-gradient-to-br from-orange-500/80 to-orange-600/80 border-2 border-background flex items-center justify-center text-xs text-white font-medium"
            >
              {String.fromCharCode(65 + i)}
            </div>
          ))}
        </div>
      </div>
    )
  }
];

const testimonials = [
  {
    id: 1,
    name: 'Sarah Johnson',
    role: 'Product Manager',
    company: 'TechCorp',
    image: '/api/placeholder/100/100',
    content: "The AI insights have transformed how I manage my team's productivity."
  },
  {
    id: 2,
    name: 'Michael Chen',
    role: 'Software Engineer',
    company: 'StartupX',
    image: '/api/placeholder/100/100',
    content: 'Finally, a productivity tool that actually understands how developers work.'
  },
  {
    id: 3,
    name: 'Emma Davis',
    role: 'Creative Director',
    company: 'DesignHub',
    image: '/api/placeholder/100/100',
    content: 'The focus tracking features have helped me maintain peak creativity.'
  }
];

const stats = [
  { label: 'Active Users', value: '50K+', icon: Users },
  { label: 'Tasks Completed', value: '1M+', icon: CheckCircle2 },
  { label: 'Time Saved', value: '500K hrs', icon: Clock },
  { label: 'AI Insights', value: '10M+', icon: Brain }
];

export default function Home() {
  const [activeFeature, setActiveFeature] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { scrollYProgress } = useScroll();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature(prev => (prev + 1) % features.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  }, []);

  return (
    <div className="relative min-h-screen" onMouseMove={handleMouseMove}>
      {/* Animated Background */}
      <div className="fixed inset-0 -z-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_var(--x,50%)_var(--y,50%),rgba(var(--primary-rgb),0.1),transparent_50%)]"
          style={{
            '--x': `${(mousePosition.x / window.innerWidth) * 100}%`,
            '--y': `${(mousePosition.y / window.innerHeight) * 100}%`
          } as any}
        />
        <motion.div 
          className="absolute inset-0 bg-gradient-to-b from-background via-background/50 to-background"
          style={{ y: backgroundY, opacity }}
        />
      </div>

      {/* Hero Section */}
      <section className="relative pt-32 pb-24 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            {/* Left Column - Text Content */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-primary/10 text-primary mb-8">
                <Sparkles className="w-4 h-4" />
                <span className="text-sm font-medium">Powered by GPT-4</span>
              </div>

              <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight mb-6">
                Work Smarter with
                <span className="block text-primary mt-2">AI Intelligence</span>
              </h1>

              <p className="text-xl text-muted-foreground mb-8 max-w-lg">
                Experience the next evolution of productivity. Our AI-powered platform adapts to your 
                workflow, providing real-time insights and automation.
              </p>

              <div className="flex flex-col sm:flex-row gap-4">
                <button className="px-8 py-4 bg-primary text-primary-foreground rounded-lg font-medium 
                                 hover:bg-primary/90 transition-colors inline-flex items-center justify-center gap-2 
                                 relative overflow-hidden group">
                  <span className="relative z-10">Start Free Trial</span>
                  <ArrowRight className="w-4 h-4 relative z-10 group-hover:translate-x-1 transition-transform" />
                  <motion.div
                    className="absolute inset-0 bg-primary-foreground mix-blend-difference"
                    initial={{ x: '-100%' }}
                    whileHover={{ x: '100%' }}
                    transition={{ duration: 0.5 }}
                  />
                </button>

                <button
                  onClick={() => setIsVideoPlaying(true)}
                  className="px-8 py-4 border border-border rounded-lg font-medium 
                           hover:bg-muted transition-colors inline-flex items-center justify-center gap-2"
                >
                  <Play className="w-4 h-4" />
                  Watch Demo
                </button>
              </div>

              {/* Stats */}
              <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-8">
                {stats.map((stat, index) => (
                  <motion.div
                    key={stat.label}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 + index * 0.1 }}
                    className="text-center"
                  >
                    <div className="font-bold text-2xl mb-1">{stat.value}</div>
                    <div className="text-sm text-muted-foreground flex items-center justify-center gap-1">
                      <stat.icon className="w-4 h-4" />
                      {stat.label}
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Right Column - Interactive Demo */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="relative"
            >
              <div className="relative rounded-xl border border-border bg-card shadow-2xl overflow-hidden">
                {/* Mock Interface */}
                <div className="p-4 border-b border-border flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div className="w-3 h-3 rounded-full bg-red-500" />
                    <div className="w-3 h-3 rounded-full bg-yellow-500" />
                    <div className="w-3 h-3 rounded-full bg-green-500" />
                  </div>
                  <div className="text-sm text-muted-foreground">productivity.ai</div>
                </div>

                <div className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-semibold">Productivity Score</h3>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-muted-foreground">Last 7 days</span>
                      <ChevronRight className="w-4 h-4" />
                    </div>
                  </div>

                  <div className="h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={mockProductivityData}>
                        <XAxis 
                          dataKey="month" 
                          stroke="hsl(var(--muted-foreground))"
                          fontSize={12}
                        />
                        <YAxis 
                          stroke="hsl(var(--muted-foreground))"
                          fontSize={12}
                        />
                        <Tooltip
                          contentStyle={{
                            background: 'hsl(var(--card))',
                            border: '1px solid hsl(var(--border))',
                            borderRadius: '8px'
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="productivity"
                          stroke="hsl(var(--primary))"
                          strokeWidth={2}
                          dot={{
                            stroke: 'hsl(var(--primary))',
                            strokeWidth: 2,
                            fill: 'hsl(var(--card))',
                            r: 4
                          }}
                          activeDot={{
                            r: 6,
                            strokeWidth: 3
                          }}
                        />
                        <Line
                          type="monotone"
                          dataKey="focus"
                          stroke="hsl(var(--primary))"
                          strokeWidth={2}
                          strokeDasharray="5 5"
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>

                  <div className="mt-6 grid grid-cols-3 gap-4">
                    {['Focus Score', 'Tasks Done', 'Time Saved'].map((label, i) => (
                      <div
                        key={label}
                        className="p-4 rounded-lg bg-muted/50 border border-border"
                      >
                        <div className="text-sm text-muted-foreground mb-1">{label}</div>
                        <div className="text-2xl font-bold">
                          {i === 2 ? '2.5hrs' : '92%'}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Decorative Elements */}
              <div className="absolute -top-4 -right-4 w-24 h-24 bg-primary/10 rounded-full blur-2xl" />
              <div className="absolute -bottom-8 -left-8 w-48 h-48 bg-primary/10 rounded-full blur-3xl" />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 relative overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center mb-16">
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-3xl md:text-4xl font-bold mb-4"
            >
              Intelligent Features
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-xl text-muted-foreground max-w-2xl mx-auto"
            >
              Experience productivity reimagined with cutting-edge AI technology
            </motion.p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
            {/* Feature List */}
            <div className="space-y-6">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.id}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className={`relative p-6 rounded-2xl cursor-pointer transition-all group
                             ${activeFeature === index ? 
                               'bg-gradient-to-r from-primary/10 to-primary/5 border-primary' : 
                               'hover:bg-muted/50'} 
                             border border-border`}
                  onClick={() => setActiveFeature(index)}
                >
                  <div className={`p-3 rounded-lg w-fit mb-4 transition-colors
                                 ${activeFeature === index ? 
                                   `bg-${feature.color}-500/20 text-${feature.color}-500` : 
                                   'bg-muted text-muted-foreground'}`}
                  >
                    {React.cloneElement(feature.icon, { className: 'w-6 h-6' })}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-muted-foreground">{feature.description}</p>
                </motion.div>
              ))}
            </div>

            {/* Feature Preview */}
            <div className="sticky top-24">
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeFeature}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="bg-card border border-border rounded-2xl p-8 shadow-2xl"
                >
                  {features[activeFeature].preview}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-24 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="relative rounded-3xl overflow-hidden p-12"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5" />
            
            <div className="relative text-center">
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Ready to Transform Your Workflow?
              </h2>
              <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
                Join thousands of professionals already using our AI-powered platform.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button className="px-8 py-4 bg-primary text-primary-foreground rounded-lg font-medium 
                                 hover:bg-primary/90 transition-colors inline-flex items-center justify-center gap-2">
                  <Rocket className="w-5 h-5" />
                  Get Started Free
                </button>
                <button className="px-8 py-4 border border-border rounded-lg font-medium 
                                 hover:bg-muted transition-colors inline-flex items-center justify-center gap-2">
                  <Calendar className="w-5 h-5" />
                  Schedule Demo
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Video Modal */}
      <AnimatePresence>
        {isVideoPlaying && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsVideoPlaying(false)}
            className="fixed inset-0 bg-background/80 backdrop-blur-sm z-50 flex items-center justify-center"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              onClick={e => e.stopPropagation()}
              className="relative w-full max-w-4xl mx-4 aspect-video rounded-2xl overflow-hidden"
            >
              <div className="absolute inset-0 bg-muted flex items-center justify-center">
                <Play className="w-16 h-16 text-primary" />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}