import React from 'react';
import { useTheme } from './hooks/useTheme';
import ThemeToggle from './components/layout/ThemeToggle';
import Home from './components/pages/Home';
import HomePerformance from './components/pages/HomePerformance';
import HomeDashboard from './components/pages/HomeDashboard';

const App = () => {
  const { theme } = useTheme();

  return (
    <div className={`min-h-screen bg-background ${theme}`}>
      {/* Theme Toggle - You can position this where needed */}
      <div className="fixed top-4 right-4 z-50">
        <ThemeToggle />
      </div>

      {/* Main Content */}
      <div className="relative">
        <Home />
        <HomeDashboard />
        <HomePerformance />
      </div>
    </div>
  );
};

export default App;