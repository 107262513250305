// store/settingsStore.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { devtools } from 'zustand/middleware';

interface AppearanceSettings {
  theme: 'light' | 'dark' | 'system';
  accentColor: 'purple' | 'blue' | 'green' | 'orange';
  fontSize: 'small' | 'medium' | 'large';
  reducedMotion: boolean;
  highContrast: boolean;
  compactMode: boolean;
}

interface NotificationSettings {
  desktop: boolean;
  sound: boolean;
  taskReminders: boolean;
  deadlineAlerts: boolean;
  aiSuggestions: boolean;
  productivityInsights: boolean;
  teamUpdates: boolean;
  reminderTiming: {
    beforeDeadline: number[]; // minutes
    duringFocus: boolean;
    afterBreak: boolean;
  };
  quietHours: {
    enabled: boolean;
    start: string; // HH:mm
    end: string; // HH:mm
    days: number[]; // 0-6, where 0 is Sunday
  };
}

interface FocusSettings {
  defaultFocusDuration: number;
  defaultBreakDuration: number;
  longBreakDuration: number;
  sessionsUntilLongBreak: number;
  autoStartBreaks: boolean;
  autoStartNextSession: boolean;
  focusModeOptions: {
    blockNotifications: boolean;
    dimInterface: boolean;
    playAmbientSound: boolean;
    selectedSound?: string;
    volume: number;
  };
}

interface TaskSettings {
  defaultView: 'board' | 'list' | 'calendar' | 'timeline';
  defaultGrouping: 'status' | 'priority' | 'tags' | 'none';
  defaultSorting: 'dueDate' | 'priority' | 'created' | 'title';
  showSubtasks: 'expanded' | 'collapsed' | 'hidden';
  showCompletedTasks: boolean;
  autoArchiveCompleted: boolean;
  archiveDelay: number; // days
  defaultEstimates: {
    low: number;
    medium: number;
    high: number;
  };
  customStatuses: Array<{
    id: string;
    name: string;
    color: string;
  }>;
}

interface AiSettings {
  enabled: boolean;
  suggestionFrequency: 'low' | 'medium' | 'high';
  proactiveAnalysis: boolean;
  analysisScope: {
    productivity: boolean;
    workload: boolean;
    patterns: boolean;
    teamDynamics: boolean;
  };
  privacyLevel: 'strict' | 'balanced' | 'relaxed';
  modelPreferences: {
    response: 'concise' | 'detailed';
    creativity: 'conservative' | 'balanced' | 'creative';
    tone: 'professional' | 'casual' | 'motivational';
  };
}

interface BackupSettings {
  autoBackup: boolean;
  backupFrequency: 'daily' | 'weekly' | 'monthly';
  backupTime: string; // HH:mm
  retentionPeriod: number; // days
  includeSettings: boolean;
  includeHistory: boolean;
  storageLocation: 'local' | 'cloud';
}

type SettingsData = {
  appearance: AppearanceSettings;
  notifications: NotificationSettings;
  focus: FocusSettings;
  tasks: TaskSettings;
  ai: AiSettings;
  backup: BackupSettings;
  isInitialized: boolean;
}

type SettingsMethods = {
  updateAppearance: (settings: Partial<AppearanceSettings>) => void;
  toggleTheme: () => void;
  setAccentColor: (color: AppearanceSettings['accentColor']) => void;
  updateNotifications: (settings: Partial<NotificationSettings>) => void;
  toggleNotificationSetting: (key: keyof Omit<NotificationSettings, 'reminderTiming' | 'quietHours'>) => void;
  updateQuietHours: (quietHours: NotificationSettings['quietHours']) => void;
  updateFocus: (settings: Partial<FocusSettings>) => void;
  updateFocusModeOptions: (options: Partial<FocusSettings['focusModeOptions']>) => void;
  updateTasks: (settings: Partial<TaskSettings>) => void;
  addCustomStatus: (status: TaskSettings['customStatuses'][0]) => void;
  removeCustomStatus: (id: string) => void;
  updateDefaultEstimates: (estimates: TaskSettings['defaultEstimates']) => void;
  updateAi: (settings: Partial<AiSettings>) => void;
  toggleAiFeature: (feature: keyof AiSettings['analysisScope']) => void;
  updateModelPreferences: (prefs: Partial<AiSettings['modelPreferences']>) => void;
  updateBackup: (settings: Partial<BackupSettings>) => void;
  toggleAutoBackup: () => void;
  resetAllSettings: () => void;
  importSettings: (settings: Partial<SettingsData>) => void;
  exportSettings: () => SettingsData;
}

type SettingsState = SettingsData & SettingsMethods;

const defaultSettings: SettingsData = {
  appearance: {
    theme: 'system',
    accentColor: 'purple',
    fontSize: 'medium',
    reducedMotion: false,
    highContrast: false,
    compactMode: false,
  },
  notifications: {
    desktop: true,
    sound: true,
    taskReminders: true,
    deadlineAlerts: true,
    aiSuggestions: true,
    productivityInsights: true,
    teamUpdates: true,
    reminderTiming: {
      beforeDeadline: [15, 60, 1440],
      duringFocus: false,
      afterBreak: true,
    },
    quietHours: {
      enabled: false,
      start: '22:00',
      end: '07:00',
      days: [0, 6],
    },
  },
  focus: {
    defaultFocusDuration: 25,
    defaultBreakDuration: 5,
    longBreakDuration: 15,
    sessionsUntilLongBreak: 4,
    autoStartBreaks: false,
    autoStartNextSession: false,
    focusModeOptions: {
      blockNotifications: true,
      dimInterface: true,
      playAmbientSound: false,
      volume: 50,
    },
  },
  tasks: {
    defaultView: 'board',
    defaultGrouping: 'status',
    defaultSorting: 'dueDate',
    showSubtasks: 'expanded',
    showCompletedTasks: true,
    autoArchiveCompleted: true,
    archiveDelay: 7,
    defaultEstimates: {
      low: 30,
      medium: 60,
      high: 120,
    },
    customStatuses: [],
  },
  ai: {
    enabled: true,
    suggestionFrequency: 'medium',
    proactiveAnalysis: true,
    analysisScope: {
      productivity: true,
      workload: true,
      patterns: true,
      teamDynamics: false,
    },
    privacyLevel: 'balanced',
    modelPreferences: {
      response: 'concise',
      creativity: 'balanced',
      tone: 'professional',
    },
  },
  backup: {
    autoBackup: true,
    backupFrequency: 'daily',
    backupTime: '02:00',
    retentionPeriod: 30,
    includeSettings: true,
    includeHistory: true,
    storageLocation: 'local',
  },
  isInitialized: false,
};

export const useSettingsStore = create<SettingsState>()(
  devtools(
    persist(
      (set, get) => ({
        ...defaultSettings,

        updateAppearance: (settings) =>
          set((state) => ({
            appearance: { ...state.appearance, ...settings },
          })),

        toggleTheme: () =>
          set((state) => ({
            appearance: {
              ...state.appearance,
              theme:
                state.appearance.theme === 'light'
                  ? 'dark'
                  : state.appearance.theme === 'dark'
                  ? 'system'
                  : 'light',
            },
          })),

        setAccentColor: (color) =>
          set((state) => ({
            appearance: { ...state.appearance, accentColor: color },
          })),

        updateNotifications: (settings) =>
          set((state) => ({
            notifications: { ...state.notifications, ...settings },
          })),

        toggleNotificationSetting: (key) =>
          set((state) => ({
            notifications: {
              ...state.notifications,
              [key]: !state.notifications[key],
            },
          })),

        updateQuietHours: (quietHours) =>
          set((state) => ({
            notifications: {
              ...state.notifications,
              quietHours,
            },
          })),

        updateFocus: (settings) =>
          set((state) => ({
            focus: { ...state.focus, ...settings },
          })),

        updateFocusModeOptions: (options) =>
          set((state) => ({
            focus: {
              ...state.focus,
              focusModeOptions: { ...state.focus.focusModeOptions, ...options },
            },
          })),

        updateTasks: (settings) =>
          set((state) => ({
            tasks: { ...state.tasks, ...settings },
          })),

        addCustomStatus: (status) =>
          set((state) => ({
            tasks: {
              ...state.tasks,
              customStatuses: [...state.tasks.customStatuses, status],
            },
          })),

        removeCustomStatus: (id) =>
          set((state) => ({
            tasks: {
              ...state.tasks,
              customStatuses: state.tasks.customStatuses.filter((s) => s.id !== id),
            },
          })),

        updateDefaultEstimates: (estimates) =>
          set((state) => ({
            tasks: {
              ...state.tasks,
              defaultEstimates: estimates,
            },
          })),

        updateAi: (settings) =>
          set((state) => ({
            ai: { ...state.ai, ...settings },
          })),

        toggleAiFeature: (feature) =>
          set((state) => ({
            ai: {
              ...state.ai,
              analysisScope: {
                ...state.ai.analysisScope,
                [feature]: !state.ai.analysisScope[feature],
              },
            },
          })),

        updateModelPreferences: (prefs) =>
          set((state) => ({
            ai: {
              ...state.ai,
              modelPreferences: { ...state.ai.modelPreferences, ...prefs },
            },
          })),

        updateBackup: (settings) =>
          set((state) => ({
            backup: { ...state.backup, ...settings },
          })),

        toggleAutoBackup: () =>
          set((state) => ({
            backup: {
              ...state.backup,
              autoBackup: !state.backup.autoBackup,
            },
          })),

        resetAllSettings: () =>
          set(defaultSettings),

        importSettings: (settings) =>
          set((state) => ({
            ...state,
            ...settings,
            isInitialized: true,
          })),

        exportSettings: () => {
          const state = get();
          const {
            updateAppearance,
            toggleTheme,
            setAccentColor,
            updateNotifications,
            toggleNotificationSetting,
            updateQuietHours,
            updateFocus,
            updateFocusModeOptions,
            updateTasks,
            addCustomStatus,
            removeCustomStatus,
            updateDefaultEstimates,
            updateAi,
            toggleAiFeature,
            updateModelPreferences,
            updateBackup,
            toggleAutoBackup,
            resetAllSettings,
            importSettings,
            exportSettings,
            ...settings
          } = state;
          return settings;
        },
      }),
      {
        name: 'settings-store',
        partialize: (state) => ({
          appearance: state.appearance,
          notifications: state.notifications,
          focus: state.focus,
          tasks: state.tasks,
          ai: state.ai,
          backup: state.backup,
          isInitialized: state.isInitialized,
        }),
      }
    )
  )
);